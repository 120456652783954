<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';

const { login, refreshProfile } = useAuthStore();
const { authenticated } = storeToRefs(useAuthStore());

const operator = ref({
  email: '',
  password: '',
});

const formValid = ref(false);

const signIn = async () => {
  if (await login(operator.value)) {
    await refreshProfile();
    if (authenticated.value) {
      // Go to the previous page
      navigateTo('/dashboard');
    }
  }
};
</script>
<template>
  <v-card>
    <div class="px-3 px-md-10 py-8">
      <h3 class="mb-2 text-center">
        <img
          src="/images/logo.png"
          :width="70"
          alt="Logo"
          class="logo"
        />
        へようこそ
      </h3>
      <br />
      <h5 class="font-600 grey--text text--darken-3 text-sm mb-5 text-center">メールとパスワードを入力してください</h5>
      <p class="text-14 mb-1">メールアドレス</p>
      <v-form
        ref="form"
        v-model="formValid"
        @submit.prevent
      >
        <v-text-field
          v-model="operator.email"
          :rules="[$rules.email, $rules.required]"
          outlined
          dense
          placeholder="example@mail.com"
          class="mb-4"
        />
        <p class="text-14 mb-1">パスワード</p>
        <v-text-field
          v-model="operator.password"
          :rules="[$rules.password, $rules.required]"
          outlined
          dense
          type="password"
          placeholder="********"
          class="mb-4"
        />
        <v-btn
          block
          color="primary"
          class="text-capitalize font-600"
          type="submit"
          :disabled="!formValid"
          @click="signIn"
        >
          ログイン
        </v-btn>
      </v-form>
    </div>
    <div class="py-4 bg-grey-light">
      <div class="text-center">
        <span class="grey--text text--darken-1">
          パスワードを忘れましたか？
          <nuxt-link
            to="/"
            class="ms-2 grey--text text--darken-4 font-600"
          >
            リセット
          </nuxt-link>
        </span>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
.logo {
  vertical-align: middle;
}
.v-card {
  min-width: 400px;
}
</style>
